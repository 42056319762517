import * as React from "react";
import { graphql } from "gatsby";
import { 
  Box, 
  Container, 
  Heading,
  Text,
  Flex } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import PageIceMask from "../components/IceMasks/PageIceMask";
import SecondaryHero from "../components/SecondaryHero/SecondaryHero";
import HomeLowerCurve from "../components/HomePage/HomeLowerCurve";


import StripeDivder from "../components/IceMasks/StripeDivider";
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";

import ContactForm from '../components/ContactForm/ContactForm'

import Footer from "../components/Footer/Footer"


const ProgramsPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  return (
    <>
    <Seo title={document.page_title.text} />
      <PageIceMask 
        title={document.info_oval_title}
        description={document.info_oval_content.richText}
        buttonOneLinkTo={document.info_oval_button_1_link.url}
        buttonOneText={document.info_oval_button_1_label}
        buttonTwoLinkTo={document.info_oval_button_2_link.url}
        buttonTwoText={document.info_oval_button_2_label}
        mr="10%"
        mt="12%"
      />
      <SecondaryHero
        secondaryImg={secondaryImage}
        alt={document.secondary_hero_image.alt}
      />
      <Box
        style={{
          width: "100%",
          marginTop: "15%",
          marginBottom: '-1px'
        }}
        display={{base: 'none', lg: 'block'}}
      >
        <HomeLowerCurve
          pageTitle="Contact Us"
          subTitle=""
        />
      </Box>
      <Box pt="20" px="2" textAlign="center" display={{base: 'block', lg: 'none'}}>
            <Heading as="h1" variant="page-title" lineHeight="1" fontSize="2.5rem">
            Contact Us
              </Heading>
            
              
          </Box>

      <Box backgroundColor="white" pt="8">
        <Container maxW="container.lg" mb="12">
          <Flex w="100%" flexWrap="wrap" justifyContent="space-between" flexDirection={{ base: 'column-reverse', md: 'row'}}>
            <Box w={{base: '100%', md: '70%'}} pr={{base: 0, lg: '4'}}>
              <Heading as="h3" mb="8" textAlign="center">
                Send us a message
              </Heading>
              <ContactForm />
            </Box>
            <Box w={{base: '100%', md: '30%'}} pl={{base: 0, lg: '2'}} mb="12">
            <Text mb="1" textAlign={{base: 'center', lg: 'left'}}>
              <strong> Snow King Sports &amp; Events Center</strong><br />
              100 E. Snow King Ave.<br />
              PO Box 10965<br />
              Jackson, WY 83002<br />
              (307) 201-1633<br />
              <a href="mailto:info@snowkingsec.com">info@snowkingsec.com</a>
            </Text>
            </Box>
          </Flex>
        </Container>
        
        <BackgroundImage
          backgroundImage={getImage(backgroundImage)}
          >
        <StripeDivder />
        
        
        <DonateNewsletterBlock
          title={document.footer_call_to_action_title.text}
          content={document.footer_call_to_action_text.richText}
          btnText={document.footer_call_to_action_button_label}
          btnLink={document.footer_call_to_action_link.url}
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "contact" } }) {
      edges {
        node {
          uid
          data {
            footer_call_to_action_title {
              text
            }
            footer_call_to_action_text {
              richText
            }
            footer_call_to_action_link {
              url
            }
            footer_call_to_action_button_label
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
            info_oval_button_1_label
            info_oval_button_1_link {
              url
              target
            }
            info_oval_button_2_label
            info_oval_button_2_link {
              url
              target
            }
            info_oval_title
            info_oval_content {
              richText
            }
            body {
              ... on PrismicPageDataBodyPageContent {
                id
                slice_type
                primary {
                  page_content {
                    richText
                  }
                }
              }
              ... on PrismicPageDataBodyBannerAdsCarousel {
                id
                slice_type
                primary {
                  banner_ads {
                    document {
                      ... on PrismicBannerAds {
                        id
                        data {
                          banner_ad {
                            mobile_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            ad_link {
                              url
                            }
                            desktop_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProgramsPage;
