import React, { useState, useRef } from "react"
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  HStack,
  RadioGroup,
  Radio
} from "@chakra-ui/react"

import { useForm } from "react-hook-form"

const VolunteerForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const formRef = useRef(null);
  
  const onSubmit = data => {
    const formData = new FormData(formRef.current);
    //console.log(Array.from(formData.entries()));
    
    setProcessing(true);

    fetch('/.netlify/functions/send-contact-form', {
              method: 'POST',
              headers: {
                "Content-Type": "application/json"
            },            
                body: JSON.stringify(data)
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(JSON.stringify(data));
                setSucceeded(true);
                reset();
            } else {
                console.log('network error')
            }
        })
    
      }

  const SuccessMessage = () => (
    <Box>
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      borderRadius="1rem"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. We'll be in touch soon.
  </AlertDescription>
    </Alert>
  </Box>
  )


  return (
    <>
      <Box w="100%" mb="24">
      { succeeded ? <SuccessMessage /> : (
        <>

        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Box  mb={8} pr={{base: 0, lg: '2'}}>
                  <FormControl id="name">
                    <FormLabel>Your Name<Text color="#bf1650" as="span">*</Text></FormLabel>
                    <Input type="text"
                        {...register("name", {required: true} )}
                        autoComplete="on"
                    />
                    {errors.name && <Text color="#bf1650">Please enter your name.</Text>}
                  </FormControl>
                </Box>
            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', lg: '50%'}} mb={8} pr={{base: 0, lg: '2'}}>
                  <FormControl id="email">
                    <FormLabel>Email address<Text color="#bf1650" as="span">*</Text></FormLabel>
                      <Input type="email" 
                        {...register("email", 
                          {
                            required: true, 
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                          }
                        }
                        )}
                        autoComplete="on"
                        />
                        {errors.email && <Text color="#bf1650">Please enter a valid email address.</Text>}
                </FormControl>
                </Box>
                <Box w={{base: '100%', lg: '50%'}} mb={8} >
                  <FormControl id="name">
                    <FormLabel>Phone</FormLabel>
                    <Input type="text"
                        {...register("phone", {
                          pattern: {
                            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
                         }
                      } )}
                        autoComplete="on"
                    />
                    {errors.phone && <Text color="#bf1650">Please enter a valid phone number.</Text>}
                  </FormControl>
                </Box>
            </Flex>
            
          <Box>
          <FormControl id="persontype" mb={8} as="fieldset">
            <FormLabel>Contacting for: </FormLabel>
            <Select placeholder="Please choose...">
                  <option value="General inquiry" {...register("persontype")}>General Inquiry</option>
                  <option value="Legaue or program information" {...register("persontype")}>League or program information</option>
                  <option value="Sponsorship/Donation" {...register("persontype")}>Sponsorship/donation</option>
                  <option value="Event or ice rental" {...register("persontype")}>Event or ice rental</option>
                  <option value="Moose Hockey team" {...register("persontype")}>Moose Hockey team</option>
            </Select>
          </FormControl>
        </Box>

                   
        <Box>
            <FormControl id="message" mb={8}>
            <FormLabel>Message<Text color="#bf1650" as="span">*</Text></FormLabel>
                <Textarea placeholder=""
                    {...register("message", {required: true} )}
                    />
                    {errors.message && <Text color="#bf1650">Please enter a message.</Text>}
            </FormControl>
        </Box>
        <Box>
          <FormControl id="mailinglist" mb={8} as="fieldset">
            <FormLabel as="legend">Would you like to join our mailing list?</FormLabel>
              <RadioGroup defaultValue="yes">
                <HStack spacing="4">
                  <Radio value="yes" {...register("mailinglist")}>Yes</Radio>
                  <Radio value="no" {...register("mailinglist")}>No</Radio>
                </HStack>
              </RadioGroup>
          </FormControl>
        </Box>
        
          <Box mt="4">
          <Button 
            disabled={!isValid || processing}
            type="submit"
            mt={4}
            isLoading={processing}
            loadingText='Submitting'
            >
              Submit
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default VolunteerForm
